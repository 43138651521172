<template>
  <div>
    <b-modal
      id="modal-login"
      @show="Limpar"
      centered
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      title="Identificação"
      button-size="lg"
    >
      <b-form-group id="stremail-grupo" label="CPF ou e-mail" label-for="stremail">
        <b-form-input
          id="stremail"
          v-model.trim="form.stremail"
          type="email"
          placeholder="Seu CPF ou endereço de e-mail"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group id="strsenha-grupo" label="Senha" label-for="strsenha">
        <b-form-input
          v-on:keyup.enter="Entrar"
          id="strsenha"
          v-model.trim="form.strsenha"
          type="password"
          placeholder="Sua senha"
          required
        ></b-form-input>
      </b-form-group>
      <template #modal-footer="{ }">
        <b-button size="sm" variant="success" @click="Entrar()"
          ><b-icon-box-arrow-in-right class="mr-2" /> Entrar</b-button
        >
        <b-button size="sm" variant="danger" @click="Recuperar()"
          ><b-icon-mailbox class="mr-2" /> Recuperar</b-button
        >
        <a id="cadastrar" href="#" @click="Cadastrar()">Cadastrar</a>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      api: this.$parent.api,
      form: {
        stremail: null,
        strsenha: null,
      },
      Toast: this.$swal.mixin({
        toast: true,
        position: "top-end",
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
      }),
    };
  },
  methods: {
    Cadastrar(){
        this.$bvModal.hide("modal-login");
        this.$parent.Cadastrar();
    },
    alertaSucesso(mensagem) {
      this.$swal("Confirmação", mensagem, "success");
    },
    alertaErro(mensagem) {
      this.Toast.fire(mensagem, "", "error");
    },
    cpfValido(strCPF) {
        var Soma;
        var Resto;
        var i = 1;
        Soma = 0;
      if (strCPF == "00000000000") return false;

      for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;

      Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11))  Resto = 0;
        if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;
        return true;
    },
    Entrar() {
      let filtro_email = /^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;
      if (this.form.stremail.length <= 0) {
        this.alertaErro("Você não preencheu o campo e-mail/CPF");
      } else if ((!filtro_email.test(this.form.stremail)) && (!this.cpfValido(this.form.stremail.replace(/\D/g, "")))) {
        this.alertaErro("E-mail/CPF inválido");
      } else if (this.form.strsenha.length <= 0) {
        this.alertaErro("Você não preencheu o campo senha");
      } else if (
        this.form.strsenha.length < 6 ||
        this.form.strsenha.length > 16
      ) {
        this.alertaErro(
          "Sua senha deverá conter no mínimo 6 e máximo 16 caracteres"
        );
      } else {
        this.axios
          .post(this.api + "autenticar", this.form)
          .then((response) => {
            //se cadastrou
            if (response.data) {
              this.$cookie.set("perfil", btoa(response.data.usuario.intperfilid), {expires: 1,path: '/painel',});
              this.$cookie.set("email", btoa(response.data.usuario.stremail), {expires: 1,path: '/painel',});
              this.$cookie.set("nome", btoa(response.data.usuario.strnome), {expires: 1,path: '/painel',});
              this.$cookie.set("token", response.data.token, { expires: 1, path: '/painel' });
              window.location.href = this.api + '../../painel/';
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response) this.alertaErro(error.response.data.erro);
            else
              this.alertaErro("Ocorreu um erro inesperado, tente novamente!");
          });

        // $.ajax({
        //   url: urlApi + "/api/login",
        //   type: "POST",
        //   dataType: "json",
        //   data: {
        //     stremail: this.form.stremail,
        //     strsenha: this.form.strsenha,
        //   },
        //   cache: false,
        //   beforeSend: function() {
        //     $.LoadingOverlay("show");
        //   },
        //   error: function(error) {
        //     $.LoadingOverlay("hide");
        //     this.alertaErro("" + error.responseJSON.erro);
        //   },
        //   success: function(retorno) {
        //     $.LoadingOverlay("hide");
        //     if (retorno.erro) {
        //       this.alertaErro("" + retorno.erro);
        //     } else {
        //       //Armazena cookie
        //       $.cookie("perfil", btoa(retorno.usuario.intperfilid), {
        //         expires: 7,
        //         path: urlApi,
        //       });
        //       $.cookie("email", btoa(retorno.usuario.stremail), {
        //         expires: 7,
        //         path: urlApi,
        //       });
        //       $.cookie("nome", btoa(retorno.usuario.strnome), {
        //         expires: 7,
        //         path: urlApi,
        //       });
        //       $.cookie("token", retorno.token, { expires: 7, path: urlApi });
        //       window.location.href = urlApi;
        //     }
        //   },
        // });
      }
    },
    // Entrar() {
    //   if (
    //     !this.form.stremail ||
    //     (this.form.stremail && this.form.stremail.length <= 0)
    //   )
    //     this.alertaErro("Preencha o campo e-mail");
    //   else if (
    //     !this.form.strsenha ||
    //     (this.form.strsenha && this.form.strsenha.length <= 0)
    //   )
    //     this.alertaErro("Preencha o campo strsenha");
    //   if (
    //     this.form.strsenha &&
    //     this.form.strsenha.length > 0 &&
    //     this.form.stremail &&
    //     this.form.stremail.length > 0
    //   ) {
    //     this.axios
    //       .post(this.api + "autenticar", this.form)
    //       .then((response) => {
    //         //se logou...
    //         if (response.data) window.location.href = "/ouvidoria/sistema";
    //       })
    //       .catch((error) => {
    //         if (error.response) this.alertaErro(error.response.data.erro);
    //         else
    //           this.alertaErro("Ocorreu um erro inesperado, tente novamente!");
    //       });
    //   }
    // },
    Recuperar() {
      if (
        !this.form.stremail ||
        (this.form.stremail && this.form.stremail.length <= 0)
      )
        return this.alertaErro("Preencha o campo e-mail");

      this.axios
        .post(this.api + "usuario/solicitarrecuperacao", this.form)
        .then((response) => {
          this.alertaSucesso(response.data.mensagem);
          this.$bvModal.hide("modal-login");
        })
        .catch((error) => {
          if (error.response) this.alertaErro(error.response.data.erro);
          else this.alertaErro("Ocorreu um erro inesperado, tente novamente!");
        });
    },
    Limpar() {
      this.form = {
        stremail: null,
        strsenha: null,
      };
    },
  },
};
</script>

<style>
#cadastrar{
  text-align: right;
  float: right;
  text-decoration: none;
}
#modal-login .modal-header {
  background-color: #235e85 !important;
  color: white !important;
}
#modal-login .modal-footer {
  justify-content: flex-start !important;
  background-color: #ececec !important;
}
.modal-footer {
  display: block !important;
}
</style>
