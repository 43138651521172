import Vue from 'vue'
import App from './App.vue'
import VueMq from 'vue-mq'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSmoothScroll from 'vue2-smooth-scroll';
import VueCookie from 'vue-cookie';
// Tell Vue to use the plugin
Vue.use(VueCookie);
Vue.use(VueSmoothScroll,{offset: -90});
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSweetalert2);
Vue.use(VueAxios, axios);
Vue.use(VueMq, {
	breakpoints: {
		xs: 576,
		sm: 768,
		md: 992,
		lg: 1200,
		xl: Infinity
	}
});
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
}).$mount('#app')
