<template>
  <div>
    <b-modal
      id="modal-redefinir"
      @show="Limpar"
      centered
      no-close-on-esc
      no-close-on-backdrop
      size="md"
      title="Redefinir senha"
      button-size="lg"
    >
      <b-form-group id="strsenha-grupo" label="Nova senha" label-for="strsenha">
        <b-form-input
          id="strsenha"
          v-model.trim="form.strsenha"
          type="password"
          placeholder="Digite a senha"
          required
        >
        </b-form-input>
      </b-form-group>
      <b-form-group
        id="strsenha_confirmation-grupo"
        label="Confirme a senha"
        label-for="strsenha_confirmation"
      >
        <b-form-input
          id="strsenha_confirmation"
          v-on:keyup.enter="Redefinir"
          v-model.trim="form.strsenha_confirmation"
          type="password"
          placeholder="Confirme a senha"
          required
        >
        </b-form-input>
      </b-form-group>
      <template #modal-footer="{ }">
        <b-button size="sm" variant="success" @click="Redefinir()"
          ><b-icon-box-arrow-in-right class="mr-2" /> Redefinir</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["token"],
  data() {
    return {
      api: this.$parent.api,
      form: {
        strsenha: null,
        strsenha_confirmation: null,
      },
      Toast: this.$swal.mixin({
        toast: true,
        position: "top-end",
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
      }),
    };
  },
  methods: {
    alertaSucesso(mensagem) {
      this.$swal("Confirmação", mensagem, "success");
    },
    alertaErro(mensagem) {
      this.Toast.fire(mensagem, "", "error");
    },
    Redefinir() {
      if (
        !this.form.strsenha ||
        (this.form.strsenha && this.form.strsenha.length <= 0)
      )
        return this.alertaErro("Preencha o Nova senha");

      if (
        !this.form.strsenha_confirmation ||
        (this.form.strsenha_confirmation &&
          this.form.strsenha_confirmation.length <= 0)
      )
        return this.alertaErro("Preencha o Confirmação de senha");

      if (this.form.strsenha_confirmation !== this.form.strsenha)
        return this.alertaErro("As senhas não coincidem!");

      this.form.strtoken = this.token;

      this.axios
        .post(this.api + "usuario/recuperar", this.form)
        .then((response) => {
          this.alertaSucesso(response.data.mensagem);
          this.$bvModal.hide("modal-redefinir");
        })
        .catch((error) => {
          if (error.response) this.alertaErro(error.response.data.erro);
          else this.alertaErro("Ocorreu um erro inesperado, tente novamente!");
        });
    },
    Limpar() {
      this.form = {
        strsenha: null,
        strsenha_confirmation: null,
      };
    },
  },
};
</script>

<style>
#modal-redefinir .modal-header {
  background-color: #235e85 !important;
  color: white !important;
}
#modal-redefinir .modal-footer {
  justify-content: flex-start !important;
  background-color: #ececec !important;
}
</style>
