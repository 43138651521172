<template>
  <div>
    <b-modal
      id="modal-cadastrar"
      @show="Iniciar"
      centered
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      title="Cadastro"
    >
      <b-row>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="strnome-grupo"
            label="Nome Completo"
            label-for="strnome"
          >
            <b-form-input
              id="strnome"
              ref="strnome"
              autocomplete="off"
              v-model.trim="cadastro.strnome"
              type="text"
              placeholder="Seu Nome Completo"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="strnome_social-grupo"
            label="Nome Social (Se possuir)"
            label-for="strnome_social"
          >
            <b-form-input
              id="strnome_social"
              ref="strnome_social"
              v-model.trim="cadastro.strnome_social"
              type="text"
              placeholder="Seu Nome Social"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group id="stremail-grupo" label="E-mail" label-for="stremail">
            <b-form-input
              id="stremail"
              ref="stremail"
              v-model.trim="cadastro.stremail"
              type="email"
              placeholder="Seu e-mail"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group id="strcpf-grupo" label="CPF" label-for="strcpf">
            <b-form-input
              id="strcpf"
              ref="strcpf"
              v-model.trim="cadastro.strcpf"
              type="tel"
              placeholder="Seu CPF"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="dtanascimento-grupo"
            label="Data de Nascimento"
            label-for="dtanascimento"
          >
            <b-form-input
              id="dtanascimento"
              ref="dtanascimento"
              v-model.trim="cadastro.dtanascimento"
              type="date"
              placeholder="Sua Data de nascimento"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="intgeneroid-grupo"
            label="Gênero"
            label-for="intgeneroid"
          >
            <b-form-select
              id="intgeneroid"
              ref="intgeneroid"
              v-model="cadastro.intgeneroid"
              :options="lista_generos"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group id="intracaid-grupo" label="Raça" label-for="intracaid">
            <b-form-select
              id="intracaid"
              ref="intracaid"
              v-model="cadastro.intracaid"
              :options="lista_raca"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="intnacionalidadeid-grupo"
            label="Nacionalidade"
            label-for="intnacionalidadeid"
          >
            <b-form-select
              id="intnacionalidadeid"
              ref="intnacionalidadeid"
              v-model="cadastro.intnacionalidadeid"
              :options="lista_nacionalidade"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="intescolaridadeid-grupo"
            label="Escolaridade"
            label-for="intescolaridadeid"
          >
            <b-form-select
              id="intescolaridadeid"
              ref="intescolaridadeid"
              v-model="cadastro.intescolaridadeid"
              :options="lista_escolaridade"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="intfaixasalarial-grupo"
            label="Faixa Salarial"
            label-for="intfaixasalarial"
          >
            <b-form-select
              id="intfaixasalarial"
              ref="intfaixasalarial"
              v-model="cadastro.intfaixasalarial"
              :options="lista_faixasalarial"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="strcelular-grupo"
            label="Nº de Celular"
            label-for="strcelular"
          >
            <b-form-input
              id="strcelular"
              ref="strcelular"
              v-model.trim="cadastro.strcelular"
              type="tel"
              placeholder="Nº de celular com DDD"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="boolwhatsapp-grupo"
            label="É whatsapp?"
            label-for="boolwhatsapp"
          >
            <b-form-select
              id="boolwhatsapp"
              ref="boolwhatsapp"
              v-model="cadastro.boolwhatsapp"
              :options="[
                { value: null, text: 'Selecione uma opção' },
                { value: 1, text: 'Sim', select: true },
                { value: 0, text: 'Não' },
              ]"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <b-form-group
            id="strendereco-grupo"
            label="Endereço"
            label-for="strendereco"
          >
            <b-form-input
              id="strendereco"
              ref="strendereco"
              v-model.trim="cadastro.strendereco"
              type="text"
              placeholder="Seu endereço"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="strnumero-grupo"
            label="Número"
            label-for="strnumero"
          >
            <b-form-input
              id="strnumero"
              ref="strnumero"
              v-model.trim="cadastro.strnumero"
              type="text"
              placeholder="Seu número"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="strcomplemento-grupo"
            label="Complemento"
            label-for="strcomplemento"
          >
            <b-form-input
              id="strcomplemento"
              ref="strcomplemento"
              v-model.trim="cadastro.strcomplemento"
              type="text"
              placeholder="Seu complemento"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="strbairro-grupo"
            label="Bairro"
            label-for="strbairro"
          >
            <b-form-input
              id="strbairro"
              ref="strbairro"
              v-model.trim="cadastro.strbairro"
              type="text"
              placeholder="Seu Bairro"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group id="strcep-grupo" label="Cep" label-for="strcep">
            <b-form-input
              id="strcep"
              ref="strcep"
              v-model.trim="cadastro.strcep"
              type="tel"
              placeholder="Seu cep"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group id="estado-grupo" label="Estado" label-for="estado">
            <b-form-select
              id="intestadoid"
              ref="intestadoid"
              v-model="cadastro.intestadoid"
              :options="lista_estado"
              @change="ListaCidade()"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group id="cidade-grupo" label="Cidade" label-for="cidade">
            <b-form-select
              id="intcidadeid"
              ref="intcidadeid"
              v-model="cadastro.intcidadeid"
              :options="lista_cidade"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group id="strsenha-grupo" label="Senha" label-for="strsenha">
            <b-form-input
              id="strsenha"
              ref="strsenha"
              v-model.trim="cadastro.strsenha"
              type="password"
              placeholder="Sua senha"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="12" md="6" lg="6" xl="6">
          <b-form-group
            id="strsenha_confirmation-grupo"
            label="Confirmação de Senha"
            label-for="senha"
          >
            <b-form-input
              v-on:keyup.enter="Cadastrar"
              id="strsenha_confirmation"
              ref="strsenha_confirmation"
              v-model.trim="cadastro.strsenha_confirmation"
              type="password"
              placeholder="Sua confirmação de senha"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{}">
        <b-button size="sm" variant="success" @click="Cadastrar()"
          ><b-icon-check-circle-fill class="mr-2" /> Cadastrar</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      api: this.$parent.api,
      lista_generos: [{ value: null, text: "Seleciona uma opção" }],
      lista_raca: [{ value: null, text: "Seleciona uma opção" }],
      lista_nacionalidade: [{ value: null, text: "Seleciona uma opção" }],
      lista_escolaridade: [{ value: null, text: "Seleciona uma opção" }],
      lista_faixasalarial: [{ value: null, text: "Seleciona uma opção" }],
      lista_estado: [{ value: null, text: "Seleciona uma opção" }],
      lista_cidade: [{ value: null, text: "Seleciona o estado" }],
      cadastro: {
        strnome: null,
        strnome_social: null,
        stremail: null,
        strcpf: null,
        dtanascimento: null,
        intgeneroid: null,
        intracaid: null,
        intnacionalidadeid: null,
        intescolaridadeid: null,
        intfaixasalarial: null,
        strcelular: null,
        boolwhatsapp: null,
        strendereco: null,
        strnumero: null,
        strcomplemento: null,
        strbairro: null,
        strcep: null,
        intestadoid: null,
        intcidadeid: null,
        strsenha: null,
        strsenha_confirmation: null,
      },
      Toast: this.$swal.mixin({
        toast: true,
        position: "top-end",
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: false,
      }),
    };
  },
  methods: {
    alertaSucesso(mensagem) {
      this.$swal("Confirmação", mensagem, "success");
    },
    alertaErro(mensagem) {
      this.Toast.fire(mensagem, "", "error");
    },
    async ListaGeneros() {
      await this.axios.get(this.api + "listar/genero").then((response) => {
        this.lista_generos = [
          { text: "Seleciona uma opção", value: null, diabled: true },
        ].concat(
          response.data.map((item) => {
            return { text: item.strgenero, value: item.intgeneroid };
          })
        );
      });
    },
    async ListaRaca() {
      await this.axios.get(this.api + "listar/raca").then((response) => {
        this.lista_raca = [
          { text: "Seleciona uma opção", value: null, diabled: true },
        ].concat(
          response.data.map((item) => {
            return { text: item.strraca, value: item.intracaid };
          })
        );
      });
    },
    async ListaNacionalidade() {
      await this.axios
        .get(this.api + "listar/nacionalidades")
        .then((response) => {
          this.lista_nacionalidade = [
            { text: "Seleciona uma opção", value: null, diabled: true },
          ].concat(
            response.data.map((item) => {
              return {
                text: item.strnacionalidade,
                value: item.intnacionalidadeid,
              };
            })
          );
        });
    },
    async ListaEscolaridade() {
      await this.axios
        .get(this.api + "listar/escolaridades")
        .then((response) => {
          this.lista_escolaridade = [
            { text: "Seleciona uma opção", value: null, diabled: true },
          ].concat(
            response.data.map((item) => {
              return {
                text: item.strescolaridade,
                value: item.intescolaridadeid,
              };
            })
          );
        });
    },
    async ListaFaixasalarial() {
      await this.axios
        .get(this.api + "listar/faixa_salarial")
        .then((response) => {
          this.lista_faixasalarial = [
            { text: "Seleciona uma opção", value: null, diabled: true },
          ].concat(
            response.data.map((item) => {
              return {
                text: item.strfaixasalarial,
                value: item.intfaixasalarial,
              };
            })
          );
        });
    },
    async ListaEstado() {
      await this.axios.get(this.api + "listar/estado").then((response) => {
        this.lista_estado = [
          { text: "Seleciona uma opção", value: null, diabled: true },
        ].concat(
          response.data.map((item) => {
            return { text: item.strestado, value: item.intestadoid };
          })
        );
      });
    },
    async ListaCidade() {
      this.cadastro.intcidadeid = null;
      await this.axios
        .get(this.api + "listar/cidade/" + this.cadastro.intestadoid)
        .then((response) => {
          this.lista_cidade = [
            { text: "Seleciona uma opção", value: null, diabled: true },
          ].concat(
            response.data.map((item) => {
              return { text: item.strcidade, value: item.intcidadeid };
            })
          );
        });
    },
    Cadastrar() {
      if (
        !this.cadastro.strnome ||
        (this.cadastro.strnome && this.cadastro.strnome.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.strnome.$parent.$el.firstChild.innerText
        );
        this.$refs.strnome.focus();
        return;
      }
      if (
        !this.cadastro.stremail ||
        (this.cadastro.stremail && this.cadastro.stremail.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.stremail.$parent.$el.firstChild.innerText
        );
        this.$refs.stremail.focus();
        return;
      }
      if (
        !this.cadastro.strcpf ||
        (this.cadastro.strcpf && this.cadastro.strcpf.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.strcpf.$parent.$el.firstChild.innerText
        );
        this.$refs.strcpf.focus();
        return;
      }
      if (
        !this.cadastro.dtanascimento ||
        (this.cadastro.dtanascimento && this.cadastro.dtanascimento.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.dtanascimento.$parent.$el.firstChild.innerText
        );
        this.$refs.dtanascimento.focus();
        return;
      }
      if (
        !this.cadastro.intgeneroid ||
        (this.cadastro.intgeneroid && this.cadastro.intgeneroid.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.intgeneroid.$parent.$el.firstChild.innerText
        );
        this.$refs.intgeneroid.focus();
        return;
      }
      if (
        !this.cadastro.intracaid ||
        (this.cadastro.intracaid && this.cadastro.intracaid.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.intracaid.$parent.$el.firstChild.innerText
        );
        this.$refs.intracaid.focus();
        return;
      }
      if (
        !this.cadastro.intnacionalidadeid ||
        (this.cadastro.intnacionalidadeid &&
          this.cadastro.intnacionalidadeid.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.intnacionalidadeid.$parent.$el.firstChild.innerText
        );
        this.$refs.intnacionalidadeid.focus();
        return;
      }
      if (
        !this.cadastro.intescolaridadeid ||
        (this.cadastro.intescolaridadeid &&
          this.cadastro.intescolaridadeid.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.intescolaridadeid.$parent.$el.firstChild.innerText
        );
        this.$refs.intescolaridadeid.focus();
        return;
      }
      if (
        !this.cadastro.intfaixasalarial ||
        (this.cadastro.intfaixasalarial &&
          this.cadastro.intfaixasalarial.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.intfaixasalarial.$parent.$el.firstChild.innerText
        );
        this.$refs.intfaixasalarial.focus();
        return;
      }
      if (
        !this.cadastro.strcelular ||
        (this.cadastro.strcelular && this.cadastro.strcelular.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.strcelular.$parent.$el.firstChild.innerText
        );
        this.$refs.strcelular.focus();
        return;
      }
      if (this.cadastro.boolwhatsapp != 1 && this.cadastro.boolwhatsapp != 0) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.boolwhatsapp.$parent.$el.firstChild.innerText
        );
        this.$refs.boolwhatsapp.focus();
        return;
      }
      if (
        !this.cadastro.strendereco ||
        (this.cadastro.strendereco && this.cadastro.strendereco.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.strendereco.$parent.$el.firstChild.innerText
        );
        this.$refs.strendereco.focus();
        return;
      }
      if (
        !this.cadastro.strnumero ||
        (this.cadastro.strnumero && this.cadastro.strnumero.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.strnumero.$parent.$el.firstChild.innerText
        );
        this.$refs.strnumero.focus();
        return;
      }
      // if (
      //   !this.cadastro.strcomplemento ||
      //   (this.cadastro.strcomplemento &&
      //     this.cadastro.strcomplemento.length <= 0)
      // ) {
      //   this.alertaErro(
      //     "Preencha o campo " +
      //       this.$refs.strcomplemento.$parent.$el.firstChild.innerText
      //   );
      //   this.$refs.strcomplemento.focus();
      //   return;
      // }
      if (
        !this.cadastro.strbairro ||
        (this.cadastro.strbairro && this.cadastro.strbairro.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.strbairro.$parent.$el.firstChild.innerText
        );
        this.$refs.strbairro.focus();
        return;
      }
      if (
        !this.cadastro.strcep ||
        (this.cadastro.strcep && this.cadastro.strcep.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.strcep.$parent.$el.firstChild.innerText
        );
        this.$refs.strcep.focus();
        return;
      }
      if (
        !this.cadastro.intestadoid ||
        (this.cadastro.intestadoid && this.cadastro.intestadoid.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.intestadoid.$parent.$el.firstChild.innerText
        );
        this.$refs.intestadoid.focus();
        return;
      }
      if (
        !this.cadastro.intcidadeid ||
        (this.cadastro.intcidadeid && this.cadastro.intcidadeid.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.intcidadeid.$parent.$el.firstChild.innerText
        );
        this.$refs.intcidadeid.focus();
        return;
      }
      if (
        !this.cadastro.strsenha ||
        (this.cadastro.strsenha && this.cadastro.strsenha.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.strsenha.$parent.$el.firstChild.innerText
        );
        this.$refs.strsenha.focus();
        return;
      }
      if (
        !this.cadastro.strsenha_confirmation ||
        (this.cadastro.strsenha_confirmation &&
          this.cadastro.strsenha_confirmation.length <= 0)
      ) {
        this.alertaErro(
          "Preencha o campo " +
            this.$refs.strsenha_confirmation.$parent.$el.firstChild.innerText
        );
        this.$refs.strsenha_confirmation.focus();
        return;
      }

      if (this.cadastro.strsenha_confirmation !== this.cadastro.strsenha) {
        this.alertaErro("A senhas não conferem!");
        this.$refs.strsenha_confirmation.focus();
        return;
      }

      this.axios
        .post(this.api + "usuario", this.cadastro)
        .then((response) => {
          //se cadastrou
          if (response.data) {
            this.alertaSucesso(response.data.mensagem);
            this.$bvModal.hide("modal-cadastrar");
          }
        })
        .catch((error) => {
          if (error.response) this.alertaErro(error.response.data.erro);
          else this.alertaErro("Ocorreu um erro inesperado, tente novamente!");
        });
    },
    Iniciar() {
      this.ListaGeneros();
      this.ListaRaca();
      this.ListaNacionalidade();
      this.ListaEscolaridade();
      this.ListaFaixasalarial();
      this.ListaEstado();
      this.cadastro = {
        strnome: null,
        strnome_social: null,
        stremail: null,
        strcpf: null,
        dtanascimento: null,
        intgeneroid: null,
        intracaid: null,
        intnacionalidadeid: null,
        intescolaridadeid: null,
        intfaixasalarial: null,
        strcelular: null,
        boolwhatsapp: null,
        strendereco: null,
        strnumero: null,
        strcomplemento: null,
        strbairro: null,
        strcep: null,
        intestadoid: null,
        intcidadeid: null,
        strsenha: null,
        strsenha_confirmation: null,
      };
    },
  },
};
</script>
<style>
#modal-cadastrar .modal-header {
  background-color: #235e85 !important;
  color: white !important;
}
#modal-cadastrar .modal-footer {
  justify-content: flex-start !important;
  background-color: #ececec !important;
}
.custom-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select {
  word-wrap: normal;
}
</style>